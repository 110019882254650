var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search" },
    [
      _vm.showHistory
        ? _c("historicDetail", {
            attrs: { procInstId: _vm.procInstId },
            on: {
              close: function ($event) {
                _vm.showHistory = false
              },
            },
          })
        : _vm._e(),
      _c(
        "Card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showHistory,
              expression: "!showHistory",
            },
          ],
        },
        [
          _c(
            "Row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openSearch,
                  expression: "openSearch",
                },
              ],
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleSearch.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "Form",
                {
                  ref: "searchForm",
                  attrs: {
                    model: _vm.searchForm,
                    inline: "",
                    "label-width": 70,
                  },
                },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "任务名称", prop: "name" } },
                    [
                      _c("Input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          type: "text",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "name", $$v)
                          },
                          expression: "searchForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    { attrs: { label: "创建时间" } },
                    [
                      _c("DatePicker", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          options: _vm.options,
                          type: "daterange",
                          format: "yyyy-MM-dd",
                          clearable: "",
                          placeholder: "选择起始时间",
                        },
                        on: { "on-change": _vm.selectDateRange },
                        model: {
                          value: _vm.selectDate,
                          callback: function ($$v) {
                            _vm.selectDate = $$v
                          },
                          expression: "selectDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    {
                      staticClass: "br",
                      staticStyle: { "margin-left": "-35px" },
                    },
                    [
                      _c(
                        "Button",
                        {
                          attrs: { type: "primary", icon: "ios-search" },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c("Button", { on: { click: _vm.handleReset } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            { staticClass: "operation" },
            [
              _c(
                "Button",
                {
                  attrs: { icon: "md-checkmark-circle-outline" },
                  on: { click: _vm.passAllTask },
                },
                [_vm._v("批量通过")]
              ),
              _c(
                "Button",
                { attrs: { icon: "md-close" }, on: { click: _vm.backAllTask } },
                [_vm._v("批量驳回")]
              ),
              _c(
                "Button",
                {
                  attrs: { icon: "md-refresh" },
                  on: { click: _vm.getDataList },
                },
                [_vm._v("刷新")]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "dashed" },
                  on: {
                    click: function ($event) {
                      _vm.openSearch = !_vm.openSearch
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.openSearch ? "关闭搜索" : "开启搜索"))]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "dashed" },
                  on: {
                    click: function ($event) {
                      _vm.openTip = !_vm.openTip
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.openTip ? "关闭提示" : "开启提示"))]
              ),
            ],
            1
          ),
          _c(
            "Row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openTip,
                  expression: "openTip",
                },
              ],
            },
            [
              _c("Alert", { attrs: { "show-icon": "" } }, [
                _vm._v(" 已选择 "),
                _c("span", { staticClass: "select-count" }, [
                  _vm._v(_vm._s(_vm.selectList.length)),
                ]),
                _vm._v(" 项 "),
                _c(
                  "a",
                  {
                    staticClass: "select-clear",
                    on: { click: _vm.clearSelectAll },
                  },
                  [_vm._v("清空")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c("Table", {
                ref: "table",
                attrs: {
                  loading: _vm.loading,
                  border: "",
                  columns: _vm.columns,
                  sortable: "custom",
                  data: _vm.data,
                },
                on: {
                  "on-sort-change": _vm.changeSort,
                  "on-selection-change": _vm.showSelect,
                },
              }),
            ],
            1
          ),
          _c(
            "Row",
            { staticClass: "page", attrs: { type: "flex", justify: "end" } },
            [
              _c("Page", {
                attrs: {
                  current: _vm.searchForm.pageNumber,
                  total: _vm.total,
                  "page-size": _vm.searchForm.pageSize,
                  "page-size-opts": [10, 20, 50],
                  size: "small",
                  "show-total": "",
                  "show-elevator": "",
                  "show-sizer": "",
                },
                on: {
                  "on-change": _vm.changePage,
                  "on-page-size-change": _vm.changePageSize,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            title: _vm.modalTaskTitle,
            "mask-closable": false,
            width: 500,
          },
          model: {
            value: _vm.modalTaskVisible,
            callback: function ($$v) {
              _vm.modalTaskVisible = $$v
            },
            expression: "modalTaskVisible",
          },
        },
        [
          _c(
            "Form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": 85,
                rules: _vm.formValidate,
              },
            },
            [
              _c(
                "FormItem",
                { attrs: { label: "审批意见", prop: "reason" } },
                [
                  _c("Input", {
                    attrs: { type: "textarea", rows: 4 },
                    model: {
                      value: _vm.form.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showAssign,
                      expression: "showAssign",
                    },
                  ],
                  attrs: {
                    label: "下一审批人",
                    prop: "assignees",
                    error: _vm.error,
                  },
                },
                [
                  _c(
                    "Select",
                    {
                      attrs: {
                        placeholder: "请选择或输入搜索",
                        filterable: "",
                        clearable: "",
                        multiple: "",
                        loading: _vm.userLoading,
                      },
                      model: {
                        value: _vm.form.assignees,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "assignees", $$v)
                        },
                        expression: "form.assignees",
                      },
                    },
                    _vm._l(_vm.assigneeList, function (item, i) {
                      return _c(
                        "Option",
                        {
                          key: i,
                          attrs: { value: item.id, label: item.nickname },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "10px" } },
                            [_vm._v(_vm._s(item.nickname))]
                          ),
                          _c("span", { staticStyle: { color: "#ccc" } }, [
                            _vm._v(_vm._s(item.username)),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCustom,
                      expression: "isCustom",
                    },
                  ],
                  attrs: {
                    label: "自定义搜索选择审批人",
                    prop: "assignees",
                    error: _vm.error,
                  },
                },
                [
                  _c(
                    "Select",
                    {
                      attrs: {
                        placeholder: "请输入用户名搜索选择用户",
                        filterable: "",
                        remote: "",
                        multiple: "",
                        "remote-method": _vm.searchUser,
                        loading: _vm.userLoading,
                      },
                      model: {
                        value: _vm.form.assignees,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "assignees", $$v)
                        },
                        expression: "form.assignees",
                      },
                    },
                    _vm._l(_vm.userList, function (item, i) {
                      return _c(
                        "Option",
                        {
                          key: i,
                          attrs: { value: item.id, label: item.nickname },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "10px" } },
                            [_vm._v(_vm._s(item.nickname))]
                          ),
                          _c("span", { staticStyle: { color: "#ccc" } }, [
                            _vm._v(_vm._s(item.username)),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isGateway,
                      expression: "isGateway",
                    },
                  ],
                  attrs: { label: "下一审批人" },
                },
                [
                  _c("span", [
                    _vm._v(
                      "分支网关处暂不支持自定义选择下一审批人，将发送给下一节点所有人"
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type == 1,
                      expression: "form.type == 1",
                    },
                  ],
                },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "驳回至" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: { filterable: "", loading: _vm.backLoading },
                          on: { "on-change": _vm.changeBackTask },
                          model: {
                            value: _vm.form.backTaskKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "backTaskKey", $$v)
                            },
                            expression: "form.backTaskKey",
                          },
                        },
                        _vm._l(_vm.backList, function (item, i) {
                          return _c(
                            "Option",
                            { key: i, attrs: { value: item.key } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.backTaskKey != -1,
                          expression: "form.backTaskKey != -1",
                        },
                      ],
                      attrs: {
                        label: "指定原节点审批人",
                        prop: "assignees",
                        error: _vm.error,
                      },
                    },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            placeholder: "请选择或输入搜索",
                            filterable: "",
                            clearable: "",
                            multiple: "",
                            loading: _vm.userLoading,
                          },
                          model: {
                            value: _vm.form.assignees,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "assignees", $$v)
                            },
                            expression: "form.assignees",
                          },
                        },
                        _vm._l(_vm.assigneeList, function (item, i) {
                          return _c(
                            "Option",
                            {
                              key: i,
                              attrs: { value: item.id, label: item.nickname },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v(_vm._s(item.nickname))]
                              ),
                              _c("span", { staticStyle: { color: "#ccc" } }, [
                                _vm._v(_vm._s(item.username)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type == 2,
                      expression: "form.type == 2",
                    },
                  ],
                  attrs: {
                    label: "选择转办人",
                    prop: "userId",
                    error: _vm.error,
                  },
                },
                [
                  _c(
                    "Select",
                    {
                      attrs: {
                        placeholder: "请输入用户名搜索选择用户",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUser,
                        loading: _vm.userLoading,
                      },
                      model: {
                        value: _vm.form.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "userId", $$v)
                        },
                        expression: "form.userId",
                      },
                    },
                    _vm._l(_vm.userList, function (item, i) {
                      return _c(
                        "Option",
                        {
                          key: i,
                          attrs: { value: item.id, label: item.nickname },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "10px" } },
                            [_vm._v(_vm._s(item.nickname))]
                          ),
                          _c("span", { staticStyle: { color: "#ccc" } }, [
                            _vm._v(_vm._s(item.username)),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "消息通知" } },
                [
                  _c(
                    "Checkbox",
                    {
                      model: {
                        value: _vm.form.sendMessage,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sendMessage", $$v)
                        },
                        expression: "form.sendMessage",
                      },
                    },
                    [_vm._v("站内消息通知")]
                  ),
                  _c(
                    "Checkbox",
                    {
                      model: {
                        value: _vm.form.sendSms,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sendSms", $$v)
                        },
                        expression: "form.sendSms",
                      },
                    },
                    [_vm._v("短信通知")]
                  ),
                  _c(
                    "Checkbox",
                    {
                      model: {
                        value: _vm.form.sendEmail,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sendEmail", $$v)
                        },
                        expression: "form.sendEmail",
                      },
                    },
                    [_vm._v("邮件通知")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.modalTaskVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.handelSubmit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { title: _vm.modalTitle, "mask-closable": false, width: 500 },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _c("Alert", { attrs: { "show-icon": "" } }, [
            _vm._v(
              _vm._s(
                _vm.modalType == 0
                  ? "注意：将默认分配给节点设定的所有可审批用户"
                  : "注意：所有流程将驳回至发起人"
              )
            ),
          ]),
          _c(
            "Form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": 85,
                rules: _vm.formValidate,
              },
            },
            [
              _c(
                "FormItem",
                { attrs: { label: "审批意见", prop: "reason" } },
                [
                  _c("Input", {
                    attrs: { type: "textarea", rows: 4 },
                    model: {
                      value: _vm.form.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "消息通知" } },
                [
                  _c(
                    "Checkbox",
                    {
                      model: {
                        value: _vm.form.sendMessage,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sendMessage", $$v)
                        },
                        expression: "form.sendMessage",
                      },
                    },
                    [_vm._v("站内消息通知")]
                  ),
                  _c(
                    "Checkbox",
                    {
                      model: {
                        value: _vm.form.sendSms,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sendSms", $$v)
                        },
                        expression: "form.sendSms",
                      },
                    },
                    [_vm._v("短信通知")]
                  ),
                  _c(
                    "Checkbox",
                    {
                      model: {
                        value: _vm.form.sendEmail,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sendEmail", $$v)
                        },
                        expression: "form.sendEmail",
                      },
                    },
                    [_vm._v("邮件通知")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.modalVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.handelSubmitAll },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }